import React, { Component } from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import SingleCourse from "./SingleCourse";
import MonthlyPricing from "./MonthlyPricing";
import YearlyPricing from "./YearlyPricing";
import LifetimePricing from "./LifetimePricing";
import { Pricing } from "../../../../stores/PricingStore/Types";

interface Props {
  handleShowSignInSideBar: (redirectUrl?: string) => void;
  pricing: Pricing;
  showSigninSidebar: boolean;
  courseSlug: string;
  singleCourseHeading?: string;
}

class Mobile extends Component<Props, {}> {
  render() {
    const {
      handleShowSignInSideBar,
      pricing,
      showSigninSidebar,
      courseSlug,
      singleCourseHeading
    } = this.props;

    return (
      <div className="normal mobile">
        <div className="ui four column centered relaxed stackable doubling container">
          <SingleCourse
            showTitle={true}
            courseSlug={courseSlug}
            handleShowSignInSideBar={handleShowSignInSideBar}
            singleCoursePricing={pricing.single_course_pricing}
            fee={pricing.fee}
            existingSubscription={pricing.existing_subscription_present}
            enrolment={pricing.enrolment_present}
            showSigninSidebar={showSigninSidebar}
            singleCourseHeading={singleCourseHeading}
          />
          <MonthlyPricing
            handleShowSignInSideBar={handleShowSignInSideBar}
            monthlyPricing={pricing.monthly_pricing}
            showSigninSidebar={showSigninSidebar}
            courseCount={pricing.course_count}
            coursesValue={pricing.courses_value}
          />
          <YearlyPricing
            handleShowSignInSideBar={handleShowSignInSideBar}
            yearlyPricing={pricing.yearly_pricing}
            fee={pricing.fee}
            existingSubscription={pricing.existing_subscription_present}
            showSigninSidebar={showSigninSidebar}
            courseCount={pricing.course_count}
            coursesValue={pricing.courses_value}
          />
          <LifetimePricing
            handleShowSignInSideBar={handleShowSignInSideBar}
            lifetimePricing={pricing.lifetime_pricing}
            showSigninSidebar={showSigninSidebar}
          />
        </div>
      </div>
    );
  }
}

export default observer(Mobile);
