import _ from "lodash";
import { postRequest } from "./httpRequest";

const indexOf = [].indexOf;

class Tour {
  constructor($tour, name1) {
    this.stopTourBlurClickHandler = this.stopTourBlurClickHandler.bind(this);
    this.onLegStart = this.onLegStart.bind(this);
    this.onLegEnd = this.onLegEnd.bind(this);
    this.createCompletion = this.createCompletion.bind(this);
    this.removeAllDimmers = this.removeAllDimmers.bind(this);
    this.onStop = this.onStop.bind(this);
    this.showToasts = this.showToasts.bind(this);
    this.hideToasts = this.hideToasts.bind(this);
    this.$tour = $tour;
    this.name = name1;
    this.createProgressIcons();
    $(document).on("click", this.stopTourBlurClickHandler);
    this.activeDimmerSelectors = [];
    this.detectMobileDevice();
    this.hideToasts();

    this.tourbus = this.$tour.tourbus({
      onLegStart: this.onLegStart,
      onLegEnd: this.onLegEnd,
      onStop: this.onStop
    });
    this.tourbus.trigger("depart.tourbus");
  }

  createProgressIcons() {
    var $progressIcons, $steps;
    $steps = _.map($("li", this.$tour), step => {
      return $(step);
    });
    $progressIcons = $("<div/>").addClass("progress-icons");
    _.each($steps, function($step) {
      var $icon, name;
      name = $step.data("progressIcon");
      $icon = $("<i/>").addClass(`${name} icon`);
      if (name === void 0) {
        $icon.hide();
      }
      return $progressIcons.append($icon);
    });
    return _.each($steps, function($step, i) {
      var name, progressIcons;
      name = $step.data("progressIcon");
      if (name !== void 0) {
        progressIcons = $progressIcons.clone();
        $(`.icon:nth-child(${i + 1})`, progressIcons).addClass("active");
        return $step.prepend(progressIcons);
      }
    });
  }

  stopTourBlurClickHandler(e) {
    // if ($(e.target).parents(".tourbus-container").length === 0) {
    //   return this.tourbus.trigger("stop.tourbus");
    // }
  }

  detectMobileDevice() {
    if ($(window).width() < 750) {
      return $("li", this.$tour).each(function(li) {
        var mobileEl;
        mobileEl = $(this).data("mobile-el");
        if (mobileEl !== void 0) {
          return $(this).data("el", mobileEl);
        }
      });
    }
  }

  onLegStart(leg, bus) {
    this.legReached = bus.currentLegIndex;
    if (leg.rawData.dimmer) {
      return _.each(leg.rawData.dimmer.split(","), (selector, i) => {
        $(selector)
          .dimmer({
            closable: false
          })
          .dimmer("show");
        return this.activeDimmerSelectors.push(selector);
      });
    }
  }

  onLegEnd(leg, bus) {
    var $nextLeg, nextDimmerSelectors;
    $nextLeg = $(bus.legEls.get(leg.index + 1));
    nextDimmerSelectors =
      $nextLeg.length > 0 ? $nextLeg.data("dimmer").split(",") : [];
    if (leg.rawData.dimmer) {
      return _.each(leg.rawData.dimmer.split(","), function(selector) {
        if (indexOf.call(nextDimmerSelectors, selector) < 0) {
          $(selector).dimmer("hide");
          return _.pull(this?.activeDimmerSelectors, selector);
        }
      });
    }
  }

  createCompletion() {
    const data = {
      tour_completion: {
        tour_name: this.name,
        progress: this.progress
      }
    };

    return postRequest(`/tour_completions.json`, data)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  removeAllDimmers() {
    return _.each(this.activeDimmerSelectors, function(dimmerSelector) {
      return $(dimmerSelector).dimmer("hide");
    });
  }

  onStop(tour) {
    this.progress = Math.round((this.legReached / (tour.totalLegs - 1)) * 100);
    this.showToasts();
    this.createCompletion();
    this.removeAllDimmers();
    return $(document).off("click", this.stopTourBlurClickHandler);
  }

  showToasts() {
    return $("#toast_wrapper").show();
  }

  hideToasts() {
    return $("#toast_wrapper").hide();
  }
}

export default Tour;
