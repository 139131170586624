import React, { Component } from "react";
import { Link } from "react-router-dom";
import qs from "qs";
import { AxiosResponse } from "axios";
import { Dropdown } from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroll-component";
import { QuestionPreview } from "../../../../stores/CoursePreviewStore/Types";
import { Answer, Enrolment } from "../../../../stores/EnrolmentStore/Types";
import { getRequest } from "../../../../utils/httpRequest";
import { errorNotification } from "../../../../utils/helpers";
import { CommentResource } from "../../../../stores/CommentStore/Types";
import AnswerUpvote from "./AnswerUpvote";
import AnswerStateModal from "../Assignment/AnswerStateModal";
import Assignment from "../Assignment";
import Discussion from "../Discussion";
import Conversations from "../Conversations";
import { QuestionIcon } from "../../Learning/Icons";

interface Props {
  enrolment: Enrolment;
  enrolmentId: number;
  lesson: QuestionPreview;
  lastLesson: QuestionPreview;
  lessonId: number;
  answerId: number;
  page: string;
  answer: Answer;
  refreshAnswers: () => void;
  getRecentNotifications: () => void;
  getAnswer: () => void;
  mode: string;
  loadingLesson: boolean;
  loadingAnswer: boolean;
  getXp: () => void;
  currentUser: any;
  search: string;
}

interface State {
  answerState: string;
  sortBy: string;
  answers: any;
  comments: CommentResource[];
  voting: boolean;
  loading: boolean;
  showModal: boolean;
  page: number;
  hasMore: boolean;
  imageUrl: string;
  enrolment: Enrolment;
  answerStateTagline: string;
  loadingComments: boolean;
}

const passedTaglines = [
  "BRAVO!",
  "MARVELLOUS!",
  "KEEP IT UP",
  "JOLLY GOOD",
  "WONDERFUL",
  "FANTASTIC",
  "KEEP GOING",
  "KEEP UP THE GOOD WORK",
  "YOU GOT THIS",
  "NICE ONE",
  "SUPERB",
  "REMARKABLE"
];

const failedTaglines = [
  "OOPS!",
  "SO CLOSE!",
  "WHOOPSIE DAISY",
  "D'OH!",
  "TRY AGAIN",
  "HOUSTON, WE HAVE A PROBLEM!",
  "KEEP CALM AND CARRY ON"
];

class AnswerUpvotes extends Component<Props, State> {
  static defaultProps = { mode: "default" };

  constructor(props) {
    super(props);

    this.state = {
      answerState: "",
      sortBy: "random",
      answers: [],
      comments: [],
      voting: false,
      loading: true,
      showModal: false,
      page: 1,
      hasMore: true,
      imageUrl: "",
      answerStateTagline: "",
      enrolment: props.enrolment,
      loadingComments: true
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchAnswers(this.state.sortBy);
    this.fetchComments();
    this.props.getRecentNotifications();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.enrolment?.id !== state.enrolment?.id) {
      return {
        enrolment: props.enrolment
      };
    }
  }

  fetchAnswers = sortBy => {
    const { enrolmentId, answerId, lessonId, search } = this.props;

    const queryParams = qs.parse(search, { ignoreQueryPrefix: true });

    return getRequest(
      `/api/enrolments/${enrolmentId}/questions/${lessonId}/answer/answer_upvotes?answer_id=${answerId}&sort_by=${sortBy}&target_answer_id=${queryParams?.target_answer_id}`
    )
      .then((response: AxiosResponse) => {
        this.setState({
          answers: response.data.answers,
          hasMore: response.data.has_more,
          loading: false
        });
      })
      .catch(error => {
        this.setState({ loading: false });

        errorNotification(
          "Error loading lesson",
          JSON.stringify(error?.response?.data)
        );
      });
  };

  changeSortBy = order => {
    this.setState({ sortBy: order });
  };

  updateAnswerState = answerState => {
    this.setState({ answerState }, () => {
      if (answerState === "passed") {
        this.setState({
          showModal: true,
          answerStateTagline: this.answerStateTagline(),
          imageUrl: this.imageUrl()
        });

        this.props.getAnswer();
      }
    });
  };

  updateEnrolment = enrolment => {
    this.setState({ enrolment });
  };

  handleHideAnswerStateModal = () => {
    this.setState({ showModal: false });
  };

  fetchComments = () => {
    return getRequest(`/api/comments.json?question_id=${this.props.lessonId}`)
      .then((response: AxiosResponse) => {
        this.setState({
          comments: response.data,
          loadingComments: true
        });
      })
      .catch(error => {
        this.setState({
          loadingComments: false
        });

        errorNotification(
          "Error loading comments",
          JSON.stringify(error.response.data)
        );
      });
  };

  fetchMoreData = () => {
    const { enrolmentId, answerId, lessonId } = this.props;
    const { page, answers, sortBy, hasMore } = this.state;

    if (!hasMore) {
      return;
    }

    const newPage = page + 1;

    setTimeout(() => {
      return getRequest(
        `/api/enrolments/${enrolmentId}/questions/${lessonId}/answer/answer_upvotes?answer_id=${answerId}&sort_by=${sortBy}&page=${newPage}`
      )
        .then((response: AxiosResponse) => {
          const newAnswers = answers.concat(response.data.answers);
          this.setState({
            answers: newAnswers,
            loading: false,
            page: newPage,
            hasMore: response.data.has_more
          });
        })
        .catch(error => {
          this.setState({
            loading: false
          });

          errorNotification(
            "Error loading lesson",
            JSON.stringify(error?.response?.data)
          );
        });
    }, 1500);
  };

  updateComments = () => {
    this.setState({ loading: false });
    this.fetchComments();
  };

  onClickSortOption = (e, option) => {
    e.preventDefault();

    const { sortBy } = this.state;

    if (sortBy === "option") {
      return;
    }

    this.setState({ sortBy: option, loading: true }, () =>
      this.fetchAnswers(option)
    );
  };

  answerStateTagline = () => {
    const { answerState } = this.state;

    if (!answerState) {
      return "";
    }

    switch (answerState) {
      case "pending":
        return "Please vote";
      case "submitted":
        return "We'll get back to you";
      case "passed":
        return passedTaglines[
          Math.floor(Math.random() * passedTaglines.length)
        ];
      case "failed":
        return failedTaglines[
          Math.floor(Math.random() * failedTaglines.length)
        ];
      default:
        return "Something went wrong";
    }
  };

  imageUrl = () => {
    const { answerState } = this.state;

    const { answer } = this.props;

    if (!answerState) {
      return "";
    }

    let base_url =
      "https://42courses-course-material.s3-us-west-1.amazonaws.com/answers/giphys";
    let flavors = answerState == "passed" ? this.range(70) : this.range(45);
    let flavor = answer
      ? answer.id % flavors.length
      : Math.floor(Math.random() * flavors.length);

    switch (answerState) {
      case "pending":
      case "submitted":
        return `${base_url}/team-pending.png`;
      case "passed":
        return `${base_url}/pass/passed-${flavor}.gif`;
      case "failed":
        return `${base_url}/fail/failed-${flavor}.gif`;
      default:
        return "Something went wrong";
    }
  };

  range = max => {
    return Array.from({ length: max }, (v, k) => k + 1);
  };

  updateUpvoteCount = (idx, count, newEnrolmentIds) => {
    let answers = [...this.state.answers];
    answers[idx] = {
      ...answers[idx],
      answer_upvotes_count: count,
      answer_upvotes_enrolment_ids: newEnrolmentIds
    };
    this.setState({ answers });
  };

  updateUpvoteComments = (idx, updatedAnswer) => {
    let answers = [...this.state.answers];

    answers[idx] = {
      ...answers[idx],
      answer: updatedAnswer
    };
    this.setState({ answers });
  };

  nextQuestionPath = () => {
    const { lesson, lastLesson, enrolment } = this.props;

    if (lesson?.id === lastLesson?.id) {
      if (enrolment.state === "passed") {
        return `/enrolments/${enrolment?.id}/certificate`;
      } else if (enrolment.state === "failed") {
        return `/enrolments/${enrolment?.id}`;
      } else {
        return lesson?.next_question_path;
      }
    } else {
      return lesson?.next_question_path;
    }
  };

  render() {
    const {
      lesson,
      answer,
      enrolmentId,
      lessonId,
      answerId,
      page,
      refreshAnswers,
      getRecentNotifications,
      loadingLesson,
      loadingAnswer,
      getXp,
      currentUser,
      search
    } = this.props;

    const {
      answers,
      loading,
      answerState,
      showModal,
      comments,
      hasMore,
      answerStateTagline,
      imageUrl,
      enrolment
    } = this.state;

    return (
      <>
        {loadingLesson || loadingAnswer ? (
          <>
            <div className="ui active inverted dimmer">
              <div className="ui text loader">Loading</div>
            </div>
            <div className="hero">
              <div
                className="bg"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${lesson?.topic?.masthead_image_url})`
                }}
              ></div>
            </div>
          </>
        ) : (
          <>
            <div
              className={`hero ${
                answerState === "passed" || answer?.state === "passed"
                  ? "passed"
                  : ""
              }`}
            >
              <div
                className="bg"
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${lesson?.topic.masthead_image_url})`
                }}
              ></div>
              <div className="course-header">
                <p>
                  Chapter {(lesson?.topic.position || 0) + 1} | Lesson{" "}
                  {(lesson?.position || 0) + 1}
                </p>
                <h1>{lesson?.name}</h1>
                <p className="minutes">{lesson?.length_minutes} min</p>
              </div>
            </div>
            <div className="ui segment" style={{ zIndex: 30 }}>
              {answerState === "passed" || answer?.state === "passed" ? (
                <div className="ui container current-lesson-container">
                  <div className="current-lesson">
                    <div className="ui text container lesson-text-target">
                      <div className="lesson-target" />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: lesson?.formatted_foreword
                        }}
                      />
                    </div>
                  </div>
                  <Assignment
                    mode={lesson?.kind}
                    enrolment={enrolment}
                    question={lesson}
                    page={page}
                    answer={answer}
                    refreshAnswers={refreshAnswers}
                    loadingAnswer={loadingAnswer}
                    getRecentNotifications={getRecentNotifications}
                    getXp={getXp}
                    currentUser={currentUser}
                    search={search}
                  />

                  <>
                    <div
                      className="ui container answer-upvotes"
                      style={{ padding: "0px" }}
                    >
                      <div className="controls">
                        <div className="previous action">
                          <Link to={lesson?.previous_question_path}>
                            <button className="circular ui icon button">
                              <i className="icon angle left"></i>
                            </button>
                            <div className="lesson">Prev</div>
                          </Link>
                        </div>
                        <div className="next action">
                          <Link to={this.nextQuestionPath()}>
                            <div className="lesson">Next</div>
                            <button className="circular ui icon button">
                              <i className="icon angle right"></i>
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div
                        className="answer-upvotes-container"
                        style={{ marginTop: "50px", width: "100%" }}
                      >
                        <div className="status-container pending-message">
                          <h3>Other people's answers</h3>
                        </div>

                        <div className="answer_upvotes--sort">
                          <div>
                            <Dropdown
                              text="Sort by"
                              className="upvotes-sort-dropdown"
                            >
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  text="Most Popular"
                                  onClick={e =>
                                    this.onClickSortOption(e, "votes")
                                  }
                                  className=""
                                />
                                <Dropdown.Item
                                  text="Most Recent"
                                  onClick={e =>
                                    this.onClickSortOption(e, "created")
                                  }
                                  className=""
                                />
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <div className="answers">
                          {this.state.page == 1 && !hasMore ? (
                            <>
                              {answers.map((ans, i) => (
                                <AnswerUpvote
                                  key={i}
                                  idx={i}
                                  answer={ans}
                                  updateAnswerState={this.updateAnswerState}
                                  updateEnrolment={this.updateEnrolment}
                                  enrolmentId={enrolmentId}
                                  lessonId={lessonId}
                                  answerId={answerId}
                                  answerState={answerState}
                                  originalAnswer={answer}
                                  updateUpvoteCount={this.updateUpvoteCount}
                                  answerUpvoteCount={ans.answer_upvotes_count}
                                  answerUpvoteEnrolmentIds={
                                    ans.answer_upvotes_enrolment_ids
                                  }
                                  currentUser={currentUser}
                                  updateUpvoteComments={
                                    this.updateUpvoteComments
                                  }
                                  search={search}
                                />
                              ))}
                            </>
                          ) : (
                            <InfiniteScroll
                              dataLength={answers.length}
                              next={this.fetchMoreData}
                              hasMore={hasMore}
                              loader={<h4>Loading...</h4>}
                              height={800}
                            >
                              {answers.map((ans, i) => (
                                <AnswerUpvote
                                  key={i}
                                  idx={i}
                                  answer={ans}
                                  updateAnswerState={this.updateAnswerState}
                                  updateEnrolment={this.updateEnrolment}
                                  enrolmentId={enrolmentId}
                                  lessonId={lessonId}
                                  answerId={answerId}
                                  answerState={answerState}
                                  originalAnswer={answer}
                                  updateUpvoteCount={this.updateUpvoteCount}
                                  answerUpvoteCount={ans.answer_upvotes_count}
                                  answerUpvoteEnrolmentIds={
                                    ans.answer_upvotes_enrolment_ids
                                  }
                                  currentUser={currentUser}
                                  updateUpvoteComments={
                                    this.updateUpvoteComments
                                  }
                                  search={search}
                                />
                              ))}
                            </InfiniteScroll>
                          )}
                        </div>
                      </div>
                    </div>
                    <Discussion
                      updateComments={this.updateComments}
                      lessonId={lessonId}
                      currentUser={currentUser}
                    />
                    <Conversations
                      comments={comments}
                      loaded={!loading}
                      questionId={lessonId}
                      currentUser={currentUser}
                      enrolmentId={enrolmentId}
                      updateComments={this.updateComments}
                    />
                  </>
                </div>
              ) : (
                <div className="ui container answer-upvotes">
                  {loading && (
                    <div className="ui active inverted dimmer">
                      <div className="ui text loader">Loading</div>
                    </div>
                  )}
                  <div className="answer-upvotes-wrapper">
                    <h3>Question</h3>
                  </div>
                  <div className="answer-upvotes-container">
                    <div className="form-header">
                      <div className="ui image">
                        <QuestionIcon />
                      </div>
                      <div className="ui question">
                        <h3
                          className="question-text"
                          dangerouslySetInnerHTML={{
                            __html: lesson.formatted_description
                          }}
                        ></h3>
                      </div>
                    </div>

                    <div className="status-container pending-message">
                      <h3>
                        Select three answers below that you find interesting
                      </h3>
                    </div>

                    <div className="answer_upvotes--sort">
                      <div>
                        <Dropdown
                          text="Sort by"
                          className="upvotes-sort-dropdown"
                        >
                          <Dropdown.Menu>
                            <Dropdown.Item
                              text="Most Popular"
                              onClick={e => this.onClickSortOption(e, "votes")}
                              className=""
                            />
                            <Dropdown.Item
                              text="Most Recent"
                              onClick={e =>
                                this.onClickSortOption(e, "created")
                              }
                              className=""
                            />
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="answers">
                      {!hasMore && this.state.page === 1 ? (
                        <>
                          {answers.map((ans, i) => (
                            <AnswerUpvote
                              key={i}
                              idx={i}
                              answer={ans}
                              updateEnrolment={this.updateEnrolment}
                              updateAnswerState={this.updateAnswerState}
                              enrolmentId={enrolmentId}
                              lessonId={lessonId}
                              answerId={answerId}
                              answerState={answerState}
                              originalAnswer={answer}
                              updateUpvoteCount={this.updateUpvoteCount}
                              answerUpvoteCount={ans.answer_upvotes_count}
                              answerUpvoteEnrolmentIds={
                                ans.answer_upvotes_enrolment_ids
                              }
                              currentUser={currentUser}
                              updateUpvoteComments={this.updateUpvoteComments}
                              search={search}
                            />
                          ))}
                        </>
                      ) : (
                        <InfiniteScroll
                          dataLength={answers.length}
                          next={this.fetchMoreData}
                          hasMore={hasMore}
                          loader={<h4>Loading...</h4>}
                        >
                          {answers.map((ans, i) => (
                            <AnswerUpvote
                              key={i}
                              idx={i}
                              answer={ans}
                              updateEnrolment={this.updateEnrolment}
                              updateAnswerState={this.updateAnswerState}
                              enrolmentId={enrolmentId}
                              lessonId={lessonId}
                              answerId={answerId}
                              answerState={answerState}
                              originalAnswer={answer}
                              updateUpvoteCount={this.updateUpvoteCount}
                              answerUpvoteCount={ans.answer_upvotes_count}
                              answerUpvoteEnrolmentIds={
                                ans.answer_upvotes_enrolment_ids
                              }
                              currentUser={currentUser}
                              updateUpvoteComments={this.updateUpvoteComments}
                              search={search}
                            />
                          ))}
                        </InfiniteScroll>
                      )}
                    </div>
                  </div>
                  <div className="controls">
                    <div className="previous action">
                      <Link to={lesson?.previous_question_path}>
                        <button className="circular ui icon button">
                          <i className="icon angle left"></i>
                        </button>
                        <div className="lesson">Prev</div>
                      </Link>
                    </div>
                    <div className="next action">
                      <Link to={this.nextQuestionPath()}>
                        <div className="lesson">Next</div>
                        <button className="circular ui icon button">
                          <i className="icon angle right"></i>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {enrolment && answerState && (
              <AnswerStateModal
                answerId={answerId}
                enrolment={enrolment}
                showModal={showModal}
                answerState={answerState}
                lesson={lesson}
                answerStateTagline={answerStateTagline}
                imageUrl={imageUrl}
                handleHideAnswerStateModal={this.handleHideAnswerStateModal}
              />
            )}
          </>
        )}
      </>
    );
  }
}

export default AnswerUpvotes;
