import React from "react";

const EnrolmentTour = () => (
  <ol className="tourbus-legs" id="enrolment-tour">
    <li
      data-align="center"
      data-class="no-caret"
      data-dimmer=".enrolment,#topic_list_wrapper"
      data-el=".course-header"
      data-orientation="top"
      data-width="300"
    >
      <h1>
        Welcome you <br />
        marvellous person!
      </h1>
      <p>We'd love to show you around..</p>
      <a
        className="ui small primary traditional button tourbus-next"
        href="javascript:void(0);"
      >
        Sure!
      </a>
      &nbsp;
      <a
        className="ui small basic button tourbus-stop"
        href="javascript:void(0);"
      >
        No thanks
      </a>
    </li>
    <li
      data-align="center"
      data-dimmer=".chapters,.hero,#topic_list_wrapper"
      data-el="#resources-target"
      data-mobile-el="#course-menu"
      data-orientation="bottom"
      data-scroll-context="175"
      data-width="300"
    >
      <h1>Interested in further reading?</h1>
      <p>Check out the Resources guide for suggestions.</p>
      <a
        className="ui small traditional primary button tourbus-next"
        href="javascript:void(0);"
      >
        Next
      </a>
    </li>
    <li
      data-align="center"
      data-dimmer=".chapters,.hero,#topic_list_wrapper"
      data-el="#lives-target"
      data-mobile-el="#course-menu"
      data-orientation="bottom"
      data-scroll-context="175"
      data-width="300"
    >
      <h1>You have 6 lives.</h1>
      <p>You can use them to re-answer questions.</p>
      <a
        className="ui small traditional primary button tourbus-next"
        href="javascript:void(0);"
      >
        Next
      </a>
    </li>
    <li
      data-align="center"
      data-dimmer=".hero,.course-header"
      data-el=".topics:first"
      data-mobile-el="#course-menu"
      data-orientation="top"
      data-scroll-context="175"
      data-width="300"
    >
      <h1>
        Jump to any question
        <br />
        any time
      </h1>
      <p>
        Suggested times are only a guide -<br />
        you set your own pace.
      </p>
      <a
        className="ui small traditional primary button tourbus-stop"
        href="javascript:void(0);"
      >
        Done
      </a>
    </li>
  </ol>
);
export default EnrolmentTour;
