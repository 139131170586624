import React from "react";

const LessonTour = () => (
  <ol className="tourbus-legs" id="lesson-tour">
    <li
      data-align="center"
      data-class="no-caret"
      data-dimmer=".none"
      data-el=".current-lesson"
      data-orientation="top"
      data-width="300"
      data-scroll-context="175"
    >
      <h1>Pssst..</h1>
      <p>Want some more handy tips to get started?</p>
      <a
        className="ui small primary traditional button tourbus-next"
        href="javascript:void(0);"
      >
        Sure!
      </a>
      &nbsp;
      <a
        className="ui small basic button tourbus-stop"
        href="javascript:void(0);"
      >
        No thanks
      </a>
    </li>
    <li
      data-align="center"
      data-dimmer=".chapters,.hero,#topic_list_wrapper"
      data-el=".answer-tour"
      data-mobile-el=".answer-tour"
      data-orientation="bottom"
      data-scroll-context="175"
      data-width="300"
    >
      <h1>See how far you've come</h1>
      <p>
        Jump to any topic or <br /> question any time.
      </p>
      <a
        className="ui small traditional primary button tourbus-next"
        href="javascript:void(0);"
      >
        Next
      </a>
    </li>
    <li
      data-align="center"
      data-dimmer=".none"
      data-el=".minutes"
      data-mobile-el=".minutes"
      data-orientation="bottom"
      data-width="300"
      data-scroll-context="300"
    >
      <h1>You set the pace</h1>
      <p>Questions have a suggested time, but nobody's timing you.</p>
      <a
        className="ui small traditional primary button tourbus-next"
        href="javascript:void(0);"
      >
        Next
      </a>
    </li>
    <li
      data-orientation="top"
      data-align="center"
      data-dimmer=".none"
      data-el=".discussion"
      data-mobile-el=".discussion"
      data-width="300"
      data-scroll-context="175"
    >
      <h1>Chat</h1>
      <p>Chat about the question topic with others in the discussion panel.</p>
      <a
        className="ui small traditional primary button tourbus-next"
        href="javascript:void(0);"
      >
        Next
      </a>
    </li>
    <li
      data-align="center"
      data-class="no-caret"
      data-dimmer=".enrolment,.hero"
      data-el=".current-lesson"
      data-orientation="top"
      data-width="300"
      data-scroll-context="175"
    >
      <h1>Good luck!</h1>
      <p>Have fun and enjoy the learning.</p>
      <a
        className="ui small traditional primary button tourbus-stop"
        href="javascript:void(0);"
      >
        Done
      </a>
    </li>
  </ol>
);

export default LessonTour;
