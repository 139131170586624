import React, { Component } from "react";
import { observer } from "mobx-react";
import { AxiosResponse } from "axios";
import Header from "./Header";
import SelectPlan from "./SelectPlan";
import BillingInfo from "./BillingInfo";
import CardDetails from "./CardDetails";
import PlanInfo from "./PlanInfo";
import Terms from "./Terms";
import ModalStore from "../../stores/ModalStore";
import TermsAndConditions from "../TermsAndConditions";
import { Props, State } from "./Types";
import { getRequest } from "../../utils/httpRequest";
import { formatCourses, formatCountries } from "./service";
import { scrollIntoView } from "../../utils/helpers";

class Checkout extends Component<Props, State> {
  modalStore = new ModalStore();

  constructor(props) {
    super(props);

    this.state = {
      selectedCourse: null,
      isCourseError: false,
      step: "plan",
      kind: "",
      courses: [],
      priceable: null,
      activeQuote: null,
      quotes: [],
      country: "",
      countries: [],
      billingType: "user",
      couponCode: props.couponCode || "",
      refCode: props.refCode || "",
      category: "",
      monthlySubsequentPayment: "",
      userDetails: {
        name: "",
        email: "",
        address: "",
        city: "",
        postalCode: "",
        country: "",
      },
      companyDetails: {
        name: "",
        email: "",
        billingName: "",
        address: "",
        city: "",
        postalCode: "",
        vatNumber: "",
        vatType: "",
        country: "",
      },
      loading: false,
      loadingSubscription: true,
      canOpenTermsModal: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.modalStore.ready();
    const { paymentUrl, slug, country } = this.props;
    if (this.props.paymentType === "course") {
      if (slug) {
        getRequest(`/api/v2/courses/${slug}/pay`)
          .then((response: AxiosResponse) => {
            const selectedCourse = slug;
            this.setState({
              courses: response.data.courses,
              selectedCourse,
              country: country,
            });

            this.updateStateFromResponse(response.data);
            if (response.data.priceable.sample) {
              this.setState({ step: "payment" });

              const element = document.getElementById("card-details");
              scrollIntoView(element);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getRequest("/api/courses/ranked")
          .then((response: AxiosResponse) => {
            const selectedCourse = slug;
            this.setState({
              courses: response.data,
              selectedCourse,
              country: country,
              loadingSubscription: false,
              loading: false,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      getRequest(paymentUrl)
        .then((response: AxiosResponse) => {
          this.updateStateFromResponse(response.data);
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
    }
  }

  componentDidUpdate(prevProps) {
    this.modalStore.ready();
    const { paymentUrl, selectedCurrency, country, paymentType } = this.props;
    if (
      paymentUrl !== prevProps.paymentUrl ||
      selectedCurrency !== prevProps.selectedCurrency
    ) {
      this.setState({ loadingSubscription: true, loading: true, country });
      const url =
        paymentType === "course"
          ? `/api/v2/courses/${this.state.selectedCourse}/pay`
          : paymentUrl;

      return getRequest(url)
        .then((response: AxiosResponse) => {
          this.updateStateFromResponse(response.data);
        })
        .catch((error) => {
          this.setState({
            loading: false,
            loadingSubscription: false,
            country: country,
          });
        });
    }
  }

  refreshQuote = () => {
    const { slug } = this.props;

    const { activeQuote, couponCode, refCode } = this.state;
    if (activeQuote?.id) {
      return getRequest(
        `/api/quote/${activeQuote?.id}?code=${encodeURIComponent(
          couponCode,
        )}&ref=${refCode}&is_renew=${slug === "renew"}`,
      ).then((response: AxiosResponse) => {
        this.updateStateFromResponse(response.data);

        if (
          response.data.active_quote.amount <= 0 &&
          this.state.step === "billing"
        ) {
          this.setState({ step: "payment" });
        }
      });
    }
  };

  refreshQuoteWithoutCoupon = (e) => {
    const { slug } = this.props;

    e.preventDefault();

    const { activeQuote } = this.state;
    this.setState({ couponCode: "", refCode: "" });
    if (activeQuote?.id) {
      return getRequest(
        `/api/quote/${activeQuote?.id}?remove_coupon=true&is_renew=${
          slug === "renew"
        }`,
      ).then((response: AxiosResponse) => {
        this.updateStateFromResponse(response.data);
      });
    }
  };

  updateStateFromResponse = (data) => {
    const { userDetails, companyDetails } = this.state;

    const updatedUserDetails = {
      ...userDetails,
      name: data.active_quote?.payer_name || userDetails.name,
      email: data.active_quote?.payer_email || userDetails.email,
      country: data.active_quote?.country,
    };

    const updatedCompanyDetails = {
      ...companyDetails,
      name: data.active_quote?.payer_name || companyDetails.name,
      email: data.active_quote?.payer_email || companyDetails.email,
      country: data.active_quote?.country,
    };

    this.setState({
      countries: data.countries,
      country: data.active_quote?.country,
      activeQuote: data.active_quote,
      quotes: data.quotes,
      priceable: data.priceable,
      category: data.category,
      kind: data.kind,
      loading: false,
      loadingSubscription: false,
      userDetails: updatedUserDetails,
      companyDetails: updatedCompanyDetails,
      monthlySubsequentPayment: data.monthly_subsequent_payment,
    });
  };

  handleChange = (key, value) => {
    this.setState((prevState) => ({ ...prevState, [key]: value }));
  };

  onCourseChange = (e, slug) => {
    const { couponCode, refCode } = this.state;

    this.setState({ loadingSubscription: true });

    return getRequest(
      `/api/courses/${slug}/pay?code=${couponCode}&ref=${refCode}`,
    ).then((response: AxiosResponse) => {
      this.updateStateFromResponse(response.data);
    });
  };

  render() {
    const { slug, path, paymentType, token, isCurrentUserPresent } = this.props;

    const {
      isCourseError,
      step,
      loading,
      courses,
      countries,
      priceable,
      country,
      selectedCourse,
      activeQuote,
      userDetails,
      companyDetails,
      billingType,
      couponCode,
      refCode,
      quotes,
      category,
      monthlySubsequentPayment,
      kind,
      loadingSubscription,
      canOpenTermsModal,
    } = this.state;

    return (
      <div className="facelift-checkout">
        <div className="checkout-wrapper">
          <Header />
          <div className="ui container">
            <div className="ui grid doubling stackable two column">
              <div className="eight wide column">
                <div className="plan-info mobile">
                  <PlanInfo
                    paymentType={paymentType}
                    slug={slug}
                    path={path}
                    isCourseError={isCourseError}
                    priceable={priceable}
                    course={selectedCourse}
                    category={category}
                    quotes={quotes}
                    couponCode={couponCode}
                    refCode={refCode}
                    activeQuote={activeQuote}
                    monthlySubsequentPayment={monthlySubsequentPayment}
                    loading={loading}
                    loadingSubscription={loadingSubscription}
                    handleChange={this.handleChange}
                    refreshQuote={this.refreshQuote}
                    refreshQuoteWithoutCoupon={this.refreshQuoteWithoutCoupon}
                  />
                </div>
                <div className="select-plan" id="select-plan">
                  <SelectPlan
                    paymentType={paymentType}
                    slug={slug}
                    path={path}
                    courses={formatCourses(courses)}
                    priceable={priceable}
                    activeQuote={activeQuote}
                    isExpanded={step === "plan"}
                    defaultCourse={slug}
                    couponCode={couponCode}
                    refCode={refCode}
                    quotes={quotes}
                    isCourseError={isCourseError}
                    loadingSubscription={loadingSubscription}
                    handleChange={this.handleChange}
                    onCourseChange={this.onCourseChange}
                  />
                </div>
                {(activeQuote?.amount > 0 ||
                  (paymentType === "course" && !selectedCourse)) && (
                  <div className="billing-info" id="billing-info">
                    <BillingInfo
                      isCurrentUserPresent={isCurrentUserPresent}
                      isExpanded={step === "billing"}
                      country={country}
                      countries={formatCountries(countries)}
                      handleChange={this.handleChange}
                      userDetails={userDetails}
                      companyDetails={companyDetails}
                    />
                  </div>
                )}
                <div className="billing-info card-details" id="card-details">
                  <CardDetails
                    token={token}
                    kind={kind}
                    activeQuote={activeQuote}
                    isExpanded={step === "payment"}
                    userDetails={userDetails}
                    companyDetails={companyDetails}
                    billingType={billingType}
                    handleChange={this.handleChange}
                    isCurrentUserPresent={isCurrentUserPresent}
                  />
                </div>
              </div>
              <div className="eight wide column section-two">
                <div className="plan-info desktop">
                  <PlanInfo
                    paymentType={paymentType}
                    slug={slug}
                    path={path}
                    isCourseError={isCourseError}
                    priceable={priceable}
                    course={selectedCourse}
                    category={category}
                    couponCode={couponCode}
                    refCode={refCode}
                    quotes={quotes}
                    activeQuote={activeQuote}
                    monthlySubsequentPayment={monthlySubsequentPayment}
                    loading={loading}
                    loadingSubscription={loadingSubscription}
                    handleChange={this.handleChange}
                    refreshQuote={this.refreshQuote}
                    refreshQuoteWithoutCoupon={this.refreshQuoteWithoutCoupon}
                  />
                </div>
                <div className="terms-and-conditions">
                  <Terms modalStore={this.modalStore} />
                </div>
                <TermsAndConditions modalStore={this.modalStore} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Checkout);
