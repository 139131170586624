export interface Waiting {
  kind: "waiting";
}

export interface Loading {
  kind: "loading";
}

export interface Ready {
  kind: "ready";
  pricing: PricingPayload;
}

export const waiting = (): Waiting => {
  return {
    kind: "waiting"
  };
};

export const loading = (): Loading => {
  return {
    kind: "loading"
  };
};

export const ready = (pricing: PricingPayload): Ready => {
  return {
    kind: "ready",
    pricing
  };
};

export type PricingState = Waiting | Loading | Ready;

export interface BaseCoursePricing {
  currency_symbol: string;
  amount: string;
  currency_code: string;
  course_price_tooltip_text: string;
  gift_url: string;
  checkout_url: string;
  currency_name: string;
}

export interface SingleCoursePricing extends BaseCoursePricing {
  header: string;
  description: string;
}

export interface MonthlyCoursePricing extends BaseCoursePricing {
  monthly_plan_present: boolean;
  monthly_amount: string;
}

export interface YearlyCoursePricing extends BaseCoursePricing {
  full_plan_quotation_present: boolean;
  full_plan_present: boolean;
  full_plan: {
    currency_symbol: string;
    amount: string;
    currency_name: string;
    currency_code: string;
  };
  monthly_plan_present: boolean;
  monthly_amount: string;
}

interface NoPricing {
  kind: "no-pricing";
}

export interface Pricing {
  kind: "pricing";
  fee: number;
  existing_subscription: string;
  existing_subscription_present: boolean;
  enrolment_present: boolean;
  user_country: string;
  user_country_name: string;
  course_count: number;
  enrolment_count: number;
  courses_value: string;
  single_course_pricing: SingleCoursePricing;
  monthly_pricing: MonthlyCoursePricing;
  yearly_pricing: YearlyCoursePricing;
  lifetime_pricing: YearlyCoursePricing;
}

export type PricingPayload = NoPricing | Pricing;
