import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Dropdown } from "semantic-ui-react";
import { userValidationSchema } from "../helpers";
import { Country, UserDetail } from "../Types";

interface Props {
  countries: Country[];
  userDetails: UserDetail;
  isCurrentUserPresent: boolean;
  previousAction: (e: any) => void;
  nextAction: (values: any) => void;
  updateUserDetails: (key: string, value: string) => void;
  updateCompanyCountryDetails: (key: string, value: string) => void;
}

class UserForm extends Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  handleChange = (e, key, setFieldValue) => {
    e.persist();

    const value = e.target.value;

    setFieldValue(key, value);

    this.props.updateUserDetails(key, value);
  };

  handleCountryChange = (e, data, setFieldValue) => {
    setFieldValue("country", data.value);

    this.props.updateUserDetails("country", data.value);
    this.props.updateCompanyCountryDetails("country", data.value);
  };

  handleSubmit = (values) => {
    this.props.nextAction(values);
  };

  render() {
    const { countries, userDetails, isCurrentUserPresent } = this.props;

    return (
      <>
        {userDetails.country && (
          <Formik
            initialValues={userDetails}
            validationSchema={userValidationSchema}
            onSubmit={this.handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form className="ui form">
                {!isCurrentUserPresent && (
                  <div className="field">
                    <Field
                      id="name"
                      name="name"
                      validate={true}
                      className="string required"
                      placeholder="e.g. Sherlock Holmes"
                      onChange={(e) =>
                        this.handleChange(e, "name", setFieldValue)
                      }
                    />
                    <label>Full name</label>
                    <ErrorMessage name="name">
                      {(msg) => <div className="error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                )}
                {!isCurrentUserPresent && (
                  <div className="field">
                    <Field
                      id="email"
                      name="email"
                      validate={true}
                      className="string required"
                      placeholder="e.g. sherlock@42courses.com"
                      onChange={(e) =>
                        this.handleChange(e, "email", setFieldValue)
                      }
                    />
                    <label>Email</label>
                    <ErrorMessage name="email">
                      {(msg) => <div className="error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                )}
                <div className="field">
                  <Field
                    id="address"
                    name="address"
                    validate={true}
                    className="string required"
                    placeholder="e.g. 221B Baker Street"
                    onChange={(e) =>
                      this.handleChange(e, "address", setFieldValue)
                    }
                  />
                  <label>Address</label>
                  <ErrorMessage name="address">
                    {(msg) => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="three fields">
                  <div className="field">
                    <Field
                      id="city"
                      name="city"
                      validate={true}
                      className="string required"
                      placeholder="e.g. London"
                      onChange={(e) =>
                        this.handleChange(e, "city", setFieldValue)
                      }
                    />
                    <label>City</label>

                    <ErrorMessage name="city">
                      {(msg) => <div className="error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="field">
                    <Field
                      id="postalCode"
                      name="postalCode"
                      validate={true}
                      className="string required"
                      placeholder="12345"
                      onChange={(e) =>
                        this.handleChange(e, "postalCode", setFieldValue)
                      }
                    />
                    <label>Zip/postcode</label>

                    <ErrorMessage name="postalCode">
                      {(msg) => <div className="error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="field">
                    <Dropdown
                      placeholder="Select Country"
                      id="select-country-dropdown"
                      fluid
                      search
                      selection
                      options={countries}
                      value={userDetails.country}
                      onChange={(e, data) =>
                        this.handleCountryChange(e, data, setFieldValue)
                      }
                    />
                    <label>Country</label>
                    <ErrorMessage name="country">
                      {(msg) => <div className="error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="action override">
                  <button
                    className="ui primary button back"
                    id="checkout-billing-back"
                    onClick={(e) => this.props.previousAction(e)}
                  >
                    Back
                  </button>
                  <button
                    className="ui primary button next"
                    type="submit"
                    id="checkout-billing-next"
                  >
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  }
}

export default UserForm;
