import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Dropdown } from "semantic-ui-react";
import Action from "../../common/Action";
import { Country, UserDetail, CompanyDetail, VatType } from "../Types";
import {
  getVatTypes,
  complexCountries,
  cutTextWithEllipsis,
  companyValidationSchema
} from "../helpers";

interface Props {
  countries: Country[];
  vatTypes: VatType[];
  userDetails: UserDetail;
  companyDetails: CompanyDetail;
  isCurrentUserPresent: boolean;
  previousAction: (e: any) => void;
  nextAction: (values: any) => void;
  updateUserDetails: (key: string, value: string) => void;
  updateCompanyDetails: (key: string, value: string) => void;
  updateCompanyCountryDetails: (key: string, value: string) => void;
}

class CompanyForm extends Component<Props, {}> {
  constructor(props) {
    super(props);
  }

  handleChange = (e, key, setFieldValue) => {
    e.persist();

    const value = e.target.value;

    setFieldValue(key, value);

    this.props.updateCompanyDetails(key, value);

    if (key === "name") {
      this.props.updateUserDetails("name", value);
    }

    if (key === "email") {
      this.props.updateUserDetails("email", value);
    }
  };

  handleCountryChange = (e, data, setFieldValue) => {
    const { value } = data;

    setFieldValue("country", value);

    this.props.updateCompanyCountryDetails("country", value);
    this.props.updateUserDetails("country", value);
  };

  handleVatTypeChange = (e, data, setFieldValue) => {
    setFieldValue("vatType", data.value);

    this.props.updateCompanyDetails("vatType", data.value);
  };

  handleSubmit = values => {
    this.props.nextAction(values);
  };

  renderVatText = () => {
    switch (this.props.companyDetails.country) {
      case "AU":
        return "ABN";
      case "NZ":
      case "IN":
        return "GST";
      default:
        return "VAT";
    }
  };

  vatLabel = () => {
    const selected = this.props.vatTypes.find(
      type => type.value === this.props.companyDetails.vatType
    );
    const text = selected ? selected.text : "";

    return cutTextWithEllipsis(text, 25);
  };

  render() {
    const {
      countries,
      userDetails,
      companyDetails,
      vatTypes,
      isCurrentUserPresent
    } = this.props;

    return (
      <>
        {companyDetails.country && (
          <Formik
            initialValues={companyDetails}
            validationSchema={companyValidationSchema}
            onSubmit={this.handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form className="ui form">
                {!isCurrentUserPresent && (
                  <div className="field">
                    <Field
                      id="name"
                      name="name"
                      validate={true}
                      className="string required"
                      placeholder="e.g. Sherlock Holmes"
                      onChange={e =>
                        this.handleChange(e, "name", setFieldValue)
                      }
                    />
                    <label>Full name</label>
                    <ErrorMessage name="name">
                      {msg => <div className="error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                )}
                {!isCurrentUserPresent && (
                  <div className="field">
                    <Field
                      id="email"
                      name="email"
                      validate={true}
                      className="string required"
                      placeholder="e.g. sherlock@42courses.com"
                      onChange={e =>
                        this.handleChange(e, "email", setFieldValue)
                      }
                    />
                    <label>Email</label>
                    <ErrorMessage name="email">
                      {msg => <div className="error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                )}
                <div className="field">
                  <Field
                    id="billingName"
                    name="billingName"
                    validate={true}
                    className="string required"
                    placeholder="e.g. Sherlock’s Detective Agency Ltd"
                    onChange={e =>
                      this.handleChange(e, "billingName", setFieldValue)
                    }
                  />
                  <label>Your company name</label>
                  <ErrorMessage name="billingName">
                    {msg => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="field">
                  <Field
                    id="address"
                    name="address"
                    validate={true}
                    className="string required"
                    placeholder="e.g. 221B Baker Street"
                    onChange={e =>
                      this.handleChange(e, "address", setFieldValue)
                    }
                  />
                  <label>Your company’s physical address</label>

                  <ErrorMessage name="address">
                    {msg => <div className="error">{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="three fields">
                  <div className="field">
                    <Field
                      id="city"
                      name="city"
                      validate={true}
                      className="string required"
                      placeholder="e.g. London"
                      onChange={e =>
                        this.handleChange(e, "city", setFieldValue)
                      }
                    />
                    <label>City</label>

                    <ErrorMessage name="city">
                      {msg => <div className="error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="field">
                    <Field
                      id="postalCode"
                      name="postalCode"
                      validate={true}
                      className="string required"
                      placeholder="e.g. NW1 6XE"
                      onChange={e =>
                        this.handleChange(e, "postalCode", setFieldValue)
                      }
                    />
                    <label>Zip/postcode</label>

                    <ErrorMessage name="postalCode">
                      {msg => <div className="error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="field">
                    <Dropdown
                      placeholder="Select Country"
                      id="select-country-dropdown"
                      fluid
                      search
                      selection
                      options={countries}
                      value={companyDetails.country}
                      onChange={(e, data) =>
                        this.handleCountryChange(e, data, setFieldValue)
                      }
                    />
                    <label>Country</label>
                    <ErrorMessage name="country">
                      {msg => <div className="error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
                {values.vatNumber.length > 3 &&
                complexCountries.includes(companyDetails.country) ? (
                  <div className="two fields">
                    <div className="field">
                      <Field
                        id="vatNumber"
                        name="vatNumber"
                        validate={true}
                        className="string"
                        placeholder="e.g. GB227725792"
                        onChange={e =>
                          this.handleChange(e, "vatNumber", setFieldValue)
                        }
                      />
                      <label>{this.renderVatText()} number (optional)</label>

                      <ErrorMessage name="vatNumber">
                        {msg => <div className="error">{msg}</div>}
                      </ErrorMessage>
                    </div>

                    <div className="field">
                      <Dropdown
                        placeholder="Select Vat Type"
                        id="select-country-dropdown"
                        fluid
                        search
                        selection
                        options={vatTypes}
                        text={this.vatLabel()}
                        value={companyDetails.vatType}
                        onChange={(e, data) =>
                          this.handleVatTypeChange(e, data, setFieldValue)
                        }
                      />
                      <label>Vat type</label>

                      <ErrorMessage name="vatNumber">
                        {msg => <div className="error">{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>
                ) : (
                  <div className="field">
                    <Field
                      id="vatNumber"
                      name="vatNumber"
                      validate={true}
                      className="string"
                      placeholder="e.g. GB227725792"
                      onChange={e =>
                        this.handleChange(e, "vatNumber", setFieldValue)
                      }
                    />
                    <label>{this.renderVatText()} number (optional)</label>
                    <ErrorMessage name="vatNumber">
                      {msg => <div className="error">{msg}</div>}
                    </ErrorMessage>
                  </div>
                )}
                <div className="action override">
                  <button
                    className="ui primary button back"
                    id="checkout-billing-back"
                    onClick={e => this.props.previousAction(e)}
                  >
                    Back
                  </button>
                  <button
                    className="ui primary button next"
                    type="submit"
                    id="checkout-billing-next"
                  >
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </>
    );
  }
}

export default CompanyForm;
