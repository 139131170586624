import React, { Component } from "react";
import { observer } from "mobx-react";
import { Route } from "react-router-dom";
import { AxiosResponse, AxiosError } from "axios";
import { DefaultNavbar, PaymentNavbar, Footer } from "./containers";
import currencyStore from "./stores/CurrencyStore";
import LoginStore from "./stores/LoginStore";
import LoginReactions from "./stores/LoginStore/LoginReactions";
import PricingStore from "./stores/PricingStore";
import PricingReactions from "./stores/PricingStore/PricingReactions";
import ProfileStore from "./stores/ProfileStore";
import ProfileReactions from "./stores/ProfileStore/ProfileReactions";
import RecentNotifications from "./containers/RecentNotifications";
import EmbeddedContent from "./containers/EmbeddedContent";
import {
  CurrentUserResource,
  XpResource
} from "./stores/CurrentUserStore/Types";
import { getRequest, postRequest } from "./utils/httpRequest";

interface State {
  showSignInSidebar: boolean;
  showLoggedInSidebar: boolean;
  showNotificationModal: boolean;
  redirectUrl: string;
  currentUser: CurrentUserResource;
  selectedCurrency: string;
  userXp: number;
}

interface Props {
  computedMatch?: {
    params: any;
    path: any;
  };
  exact: boolean;
  path: any;
  component: any;
  layout?: string;
  mode?: string;
  showRecentNotifications?: boolean;
}

class DynamicLayoutRoute extends Component<Props, State> {
  loginStore = new LoginStore();
  pricingStore = new PricingStore();
  profileStore = new ProfileStore();

  constructor(props) {
    super(props);

    this.state = {
      showSignInSidebar: false,
      showLoggedInSidebar: false,
      showNotificationModal: false,
      redirectUrl: "",
      currentUser: {
        id: null,
        kind: "current-user-absent",
        selected_currency: currencyStore.isoCode
      },
      userXp: 0,
      selectedCurrency: ""
    };
  }

  componentDidMount() {
    this.loginStore.loading();
    this.pricingStore.loading();
    this.profileStore.loading();

    this.getCurrentUser();
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    const isEnrolmentPath = this.props.computedMatch.path.includes(
      "enrolments/:enrolmentId/questions"
    );

    if (!isEnrolmentPath) {
      $("chat-app").css({ display: "none" });
    }
  }

  getCurrentUser = () => {
    const enrolmentId = this.props.computedMatch.params.enrolmentId;

    return getRequest(`/api/current_user.json?enrolment_id=${enrolmentId}`)
      .then((response: AxiosResponse<CurrentUserResource>) => {
        this.setState({
          currentUser: response.data,
          selectedCurrency: response.data.selected_currency
        });

        if (response.data.kind === "current-user") {
          this.getXp(response.data.id);

          (window as any).honeybadger?.setContext({
            user_id: response.data.id,
            user_email: response.data.email
          });
        }
      })
      .catch(error => {
        (window as any).honeybadger?.notify(error?.response?.data?.error);
      });
  };

  getXp = userId => {
    return getRequest(`/api/users/${userId}/xps.json`)
      .then((response: AxiosResponse<XpResource>) => {
        this.setState({
          userXp: response.data.xp
        });
      })
      .catch(error => {
        (window as any).honeybadger?.notify(error?.response?.data?.error);
      });
  };

  actualRouteComponent = () => {
    const { component: RoutedComponent, ...rest } = this.props;
    return (
      <div id="landing">
        <Route
          {...rest}
          render={props => (
            <RoutedComponent
              {...props}
              {...rest}
              handleShowSignInSideBar={this.handleShowSignInSideBar}
              pricingStore={this.pricingStore}
              profileStore={this.profileStore}
              getCurrentUser={this.getCurrentUser}
              currentUser={this.state.currentUser}
              showNotificationModal={this.state.showNotificationModal}
              handleShowNotificationModal={this.handleShowNotificationModal}
              selectedCurrency={this.state.selectedCurrency}
              getXp={this.getXp}
            />
          )}
        />
      </div>
    );
  };

  handleShowSignInSideBar = (redirectUrl?: string) => {
    const afterLoginUrl = redirectUrl || "";
    this.setState({ showSignInSidebar: true, redirectUrl: afterLoginUrl });
    $(".pusher").addClass("dimmed");

    if (redirectUrl) {
      postRequest("/api/set_enroller_path", {
        enroller_path: afterLoginUrl
      })
        .then(() => console.log("set"))
        .catch(error => {
          (window as any).honeybadger?.notify(error?.response?.data?.error);
        });
    }
    const that = this;

    $(".pusher").bind("click", function() {
      that.handleHideSignInSideBar();
    });
  };

  handleHideSignInSideBar = () => {
    this.setState({ showSignInSidebar: false, redirectUrl: "" });
    $(".pusher").removeClass("dimmed");
    $(".pusher").unbind("click");
  };

  handleShowLoggedInSideBar = () => {
    this.setState({ showLoggedInSidebar: true });
    $(".pusher").addClass("dimmed");

    const that = this;

    $(".pusher").bind("click", function() {
      that.handleHideLoggedInSideBar();
    });
  };

  handleHideLoggedInSideBar = () => {
    this.setState({ showLoggedInSidebar: false });
    $(".pusher").removeClass("dimmed");
    $(".pusher").unbind("click");
  };

  handleShowNotificationModal = val =>
    this.setState({ showNotificationModal: val });

  onClickHeaderLogo = () => {
    (window as any).location = "/";
  };

  loginUser = (e: React.MouseEvent<HTMLElement>) => {
    const { redirectUrl } = this.state;

    e.preventDefault();
    this.loginStore.saving(redirectUrl);
  };

  updateSelectedCurrency = (isoCode: string) => {
    this.setState({ selectedCurrency: isoCode });
  };

  renderNav = () => {
    const { layout, showRecentNotifications } = this.props;
    const {
      showSignInSidebar,
      showLoggedInSidebar,
      redirectUrl,
      currentUser,
      userXp
    } = this.state;

    switch (layout) {
      case "setup":
      case "payment":
        return (
          <div>
            <PaymentNavbar currentUser={currentUser} />
            {this.actualRouteComponent()}
            <Footer
              pricingStore={this.pricingStore}
              updateSelectedCurrency={this.updateSelectedCurrency}
              layout={layout}
            />
          </div>
        );
      default: {
        return (
          <div>
            <DefaultNavbar
              showSignInSidebar={showSignInSidebar}
              handleShowSignInSideBar={this.handleShowSignInSideBar}
              handleHideSignInSideBar={this.handleHideSignInSideBar}
              showLoggedInSidebar={showLoggedInSidebar}
              handleShowLoggedInSideBar={this.handleShowLoggedInSideBar}
              handleHideLoggedInSideBar={this.handleHideLoggedInSideBar}
              loginStore={this.loginStore}
              loginUser={this.loginUser}
              redirectUrl={redirectUrl}
              onClickHeaderLogo={this.onClickHeaderLogo}
              currentUser={currentUser}
              profileStore={this.profileStore}
              getCurrentUser={this.getCurrentUser}
              handleShowNotificationModal={this.handleShowNotificationModal}
              showNotificationModal={this.state.showNotificationModal}
              layout={layout}
              userXp={userXp}
            />
            {this.actualRouteComponent()}
            <Footer
              pricingStore={this.pricingStore}
              updateSelectedCurrency={this.updateSelectedCurrency}
              layout={layout}
            />
            <RecentNotifications
              showRecentNotifications={showRecentNotifications}
            />
            <EmbeddedContent />
          </div>
        );
      }
    }
  };

  render() {
    const { layout } = this.props;
    return (
      <>
        {this.renderNav()}
        <LoginReactions store={this.loginStore} />
        {layout !== "payment" && (
          <PricingReactions
            store={this.pricingStore}
            params={this.props.computedMatch.params}
          />
        )}
        {layout !== "payment" && <ProfileReactions store={this.profileStore} />}
      </>
    );
  }
}

export default observer(DynamicLayoutRoute);
