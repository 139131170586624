import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { observer } from "mobx-react";
import Routes from "./routes";
import RootStore from "./stores/RootStore";
import { RootContext } from "./stores/RootStore/Types";
import RootReactions from "./stores/RootStore/RootReactions";
import Cookies from "./containers/Cookies";
import Ads from "./containers/Ads";
import "currency-flags/dist/currency-flags.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/index.scss";

interface Props {
  rootStore: RootStore;
}

class App extends Component {
  rootStore = new RootStore();

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.rootStore.loading();
  }

  renderProvider = (rootPayload, loaded) => (
    <Router>
      <RootContext.Provider
        value={{
          rootPayload: rootPayload,
          loaded
        }}
      >
        <Routes />
        <RootReactions store={this.rootStore} />
        <Cookies />
        <Ads />
      </RootContext.Provider>
    </Router>
  );

  render() {
    // switch (this.rootStore.state.kind) {
    //   case "ready":
    //     return this.renderProvider(this.rootStore.state.rootPayload, true);
    //
    //   default:
    //     return this.renderProvider(undefined, false);
    // }

    return <div />;
  }
}

export default observer(App);
