import React, { Component } from "react";
import {
  AccordionTitle,
  AccordionContent,
  Accordion,
  Icon,
} from "semantic-ui-react";
import { getRequest } from "../../../utils/httpRequest";
import { AxiosResponse } from "axios";
import { LeaderboardSetup } from "../Leaderboard/Types";

interface Props {
  match: {
    params: {
      slug: string;
    };
  };
}

interface State {
  allocateCoursesResults: AllocateCoursesResult[];
  activeIndex: number;
}

interface AllocateCoursesResult {
  creator_name: string;
  created_at: string;
  result: {
    user_name: string;
    course_name: string;
    status: string;
    message: string;
    error: string;
  }[];
}

class AllocateCoursesResult extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      allocateCoursesResults: [],
      activeIndex: null,
    };
  }

  componentDidMount() {
    const slug = this.props.match.params.slug;

    getRequest(`/api/teams/${slug}/allocate_courses.json`)
      .then((response: AxiosResponse<AllocateCoursesResult[]>) => {
        this.setState({ allocateCoursesResults: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };
  render() {
    const { activeIndex, allocateCoursesResults } = this.state;

    return (
      <div className="ui facelift-landing-page pages">
        <div className="ui container" style={{ padding: "2em 0 4em" }}>
          <h2>Allocate Courses Results</h2>
          <Accordion styled>
            {allocateCoursesResults.map((result, index) => (
              <>
                <AccordionTitle
                  active={activeIndex === index}
                  index={index}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  {result.creator_name} - {result.created_at}
                </AccordionTitle>
                <AccordionContent active={activeIndex === index}>
                  <table className="ui celled table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Course</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {result?.result?.map((result, index) => (
                        <tr key={index}>
                          <td data-label="Name">{result.user_name}</td>
                          <td data-label="Course">{result.course_name}</td>
                          {result.status === "enrolled" ? (
                            <td data-label="Status">
                              {result.message || "Enrolled"}
                            </td>
                          ) : (
                            <td data-label="Status">{result.error}</td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </AccordionContent>
              </>
            ))}
          </Accordion>
        </div>
      </div>
    );
  }
}

export default AllocateCoursesResult;
